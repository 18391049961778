import React from "react";
import PageHeader from "../components/common/PageHeader";
import PageMeta from "../components/common/PageMeta";
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";
import RepositoriesList from "../components/repositories/RepositoriesList";
import FooterSocial from "../layout/Footer/FooterSocial";

const Repositories = () => {
    return (
        <Layout>
            <PageMeta title="Contact-Software &amp; IT Solutions HTML Template" />
            <Navbar navDark />
            <PageHeader title="Repositories" />
            <RepositoriesList />
            <FooterSocial />
        </Layout>
    );
};

export default Repositories;
