import React from "react";
import { Link } from "react-router-dom";

const FooterOne = ({ footerLight, style, footerGradient }) => {
    return (
        <>
            <footer className="footer-section">
                <div
                    className={`footer-bottom ${
                        footerLight ? "footer-light" : "bg-dark"
                    } ${footerGradient ? "bg-gradient" : ""} text-white py-4`}
                >
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-7 col-lg-7">
                                <div className="copyright-text">
                                    <p className="mb-lg-0 mb-md-0">
                                        &copy; 2023 NoteBook.com Rights
                                        Reserved. Designed By{" "}
                                        <a
                                            href="https://github.com/SauarbhPatel"
                                            className="text-decoration-none ms-2"
                                            target="_blank"
                                        >
                                            Saurabh
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="footer-single-col text-start text-lg-end text-md-end">
                                    <ul className="list-unstyled list-inline footer-social-list mb-0">
                                        <li className="list-inline-item">
                                            <a
                                                href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile"
                                                target="_blank"
                                            >
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                        </li>

                                        <li className="list-inline-item">
                                            <a
                                                href="https://github.com/SauarbhPatel"
                                                target="_blank"
                                            >
                                                <i className="fab fa-github"></i>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a
                                                href="https://www.instagram.com/saurabhpatel_06_9/"
                                                target="_blank"
                                            >
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default FooterOne;
