import React, { useEffect, useState } from "react";
import Routers from "./routers";

// Swiper CSS
import "swiper/css";
import "swiper/css/navigation";

//bootstrap
import "bootstrap/dist/js/bootstrap.bundle";

//Modal Video
import "react-modal-video/scss/modal-video.scss";
import {
    __makeGetCookiesRequest,
    __makeGetSessionPostRequest,
    __makeGetUserGetRequest,
    __makeSetCookiesRequest,
} from "./functions/apifun";
import { __authToken, __userToken } from "./functions/constantfun";
import Loader from "./components/loading/Loader";
import { PacmanLoader } from "react-spinners";
import OTPInput from "otp-input-react";
import ProfileImage from "./components/profileImage/ProfileImage";

function App() {
    const [loading, setloading] = useState(true);
    const [showotp, setshowotp] = useState(false);
    const [verification, setVerification] = useState({
        token: "",
        authToken: "",
    });
    const [error, setError] = useState({});

    const __handleCheckUser = () => {
        if (!__userToken()) {
            return setTimeout(() => {
                setloading(false);
            }, 3000);
        }
        if (__authToken()) return setloading(false);

        setloading(true);
        __makeGetUserGetRequest()
            .then((res) => {
                if (res.response.response_code === "200") {
                    sessionStorage.setItem("name", res.data.user.name);
                    sessionStorage.setItem("email", res.data.user.email);
                    sessionStorage.setItem(
                        "user_name",
                        res.data.user.user_name
                    );
                    sessionStorage.setItem("session", res.data.sesssionToken);
                    window.location.reload();
                } else if (res.response.response_code === "201") {
                    setTimeout(() => {
                        !__authToken() && setshowotp(true);
                    }, 3000);
                    sessionStorage.setItem("name", res.data.user.name);
                    sessionStorage.setItem("email", res.data.user.email);
                    sessionStorage.setItem(
                        "user_name",
                        res.data.user.user_name
                    );
                } else {
                    localStorage.clear();
                    sessionStorage.clear();
                }
                setTimeout(() => {
                    setloading(false);
                }, 3000);
            })
            .catch((error) => {
                console.error(error);
                setTimeout(() => {
                    setloading(false);
                }, 3000);
                localStorage.clear();
                sessionStorage.clear();
            });
    };

    useEffect(() => {
        __handleCheckUser();
    }, []);

    const [time, setTime] = React.useState(600);

    window.addEventListener("mousemove", (e) => {
        !showotp && setTime(600);
    });

    useEffect(() => {
        if (!sessionStorage.getItem("session")) return;
        time == 0 && setshowotp(true);
        const interval = setInterval(() => {
            console.log(time);
            time == 0 && sessionStorage.removeItem("session");
            time > 0 && setTime(time - 1);
            time == 0 && clearInterval(interval);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [time]);

    return (
        <>
            <Routers />
            {/* {<ProfileImage />} */}
            {loading && !showotp && <Loader />}
            {showotp && otpCom()}
        </>
    );

    function otpCom() {
        const __handleVerification = (event) => {
            if (!verification.token.trim()) {
                return setError({
                    otp: "Fill Verification Code!",
                });
            } else {
                setError({});
            }
            setloading(true);
            __makeGetSessionPostRequest(verification)
                .then((res) => {
                    console.log(res.response.response_code);
                    if (res.response.response_code == "200") {
                        setshowotp(false);
                        // console.log(res);
                        // localStorage.setItem("auth-token", res.data.authtoken);
                        // sessionStorage.setItem("name", res.data.user_name);
                        // navigate("/");
                        sessionStorage.setItem(
                            "session",
                            res.data.sesssionToken
                        );
                        window.location.reload();
                    } else {
                        setError({
                            otp: res.response.response_message,
                        });
                    }
                    setloading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setloading(false);
                    setError({
                        otp: "Some Technical Issue!\n Please try after some time",
                    });
                });
        };

        return (
            <div
                className="position-fixed d-flex justify-content-center align-items-center z-10"
                style={{ inset: 1, background: "rgba(255,255,255,0.2)" }}
            >
                <div className="card   bg-gradient border-0">
                    <div className="cardbody p-5 pb-4 d-flex flex-column align-items-center ">
                        <h1 className="  mb-2 fs-1">
                            Welcome back 👍
                            <span className="text-pink">
                                {sessionStorage.getItem("name")}
                            </span>
                        </h1>
                        <h4 className="  mb-5">
                            Please enter your lock password
                        </h4>
                        <OTPInput
                            value={verification.token}
                            onChange={(value) => {
                                setVerification((prev) => ({
                                    ...prev,
                                    token: value,
                                }));
                            }}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                        />
                        {error?.otp && (
                            <p
                                style={{
                                    fontSize: 11,
                                    color: "red",
                                    padding: 0,
                                    margin: 0,
                                }}
                                className="mt-2"
                            >
                                {error?.otp}
                            </p>
                        )}
                        {error?.success && (
                            <p
                                style={{
                                    fontSize: 11,
                                    color: "green",
                                    padding: 0,
                                    margin: 0,
                                }}
                                className="mt-2"
                            >
                                {error?.success}
                            </p>
                        )}

                        <button
                            // type="submit"
                            onClick={() => __handleVerification()}
                            className="btn btn-primary mt-5 d-block w-100 text-center d-flex justify-content-center align-items-center"
                        >
                            {loading && showotp ? (
                                <PacmanLoader color="#fff" size={13} />
                            ) : (
                                "Submit"
                            )}
                        </button>
                        <span
                            className="text-pink mt-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                localStorage.clear();
                                sessionStorage.clear();
                                window.location.reload();
                            }}
                        >
                            Logout
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
