import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ModalVideo from "react-modal-video";

const HeroTen = () => {
    const [isOpen, setOpen] = useState(false);
    let navigate = useNavigate();

    const __handleCheckLogin = () => {
        if (localStorage.getItem("auth-token")) return;
        navigate("/login");
    };

    useEffect(() => {
        __handleCheckLogin();
    }, []);
    return (
        <section
            className="hero-section text-white hero-ten"
            style={{
                background:
                    "url('assets/img/cyber_banner_img.png')no-repeat center center",
            }}
        >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                            <h1 className="fw-bold display-3 text-white">
                                Welcome to NoteBook.com
                            </h1>
                            <p className="lead">
                                Our website offers you a secure and private way
                                to store your sensitive information, such as
                                notes and passwords. With end-to-end encryption,
                                your data is safeguarded from prying eyes at
                                every step, ensuring that only you have access
                                to your secrets.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-8 mt-5">
                        <div className="hero-img position-relative circle-shape-images">
                            <img
                                src="assets/img/cyber_banner_image.svg"
                                alt="hero img"
                                className="img-fluid position-relative z-5"
                            />
                        </div>
                    </div>
                </div>
                <div className="cyber-down-btn text-center position-relative d-none d-lg-block z-2">
                    {/* <a href="#cyber-about" className="text-primary">
                        <i className="far fa-arrow-down"></i>
                    </a> */}
                    <a href="#!" className="text-pink">
                        <i className="far fa-arrow-up"></i>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default HeroTen;
