import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    __makeEmailVerificationPostRequest,
    __makeLoginPostRequest,
    __makeResendVerficationCodePostRequest,
} from "../../functions/apifun";
import OTPInput from "otp-input-react";
import { PacmanLoader } from "react-spinners";
import { __hasAnyValue } from "../../functions/constantfun";

const LoginScreen = () => {
    let navigate = useNavigate();
    const [userdetails, setuser] = useState({
        user_id: "demo@gmail.com",
        password: "demo",
    });
    const [showotp, setshowotp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [verification, setVerification] = useState({
        token: "",
        authToken: "",
    });

    const __handleSubmit = () => {
        let alertdata = { user_id: "", password: "" };

        if (!userdetails.user_id.trim()) {
            alertdata.user_id = "Fill your username or email id";
        }
        if (!userdetails.password) {
            alertdata.password = "Fill your password";
        }
        setError((prv) => ({
            ...prv,
            ...alertdata,
        }));

        if (__hasAnyValue(alertdata)) {
            return;
        }
        setLoading(true);

        __makeLoginPostRequest(userdetails)
            .then((res) => {
                console.log(res);
                setLoading(false);

                if (res.response.response_code === "200") {
                    localStorage.setItem("auth-token", res.data.authtoken);
                    sessionStorage.setItem("name", res.data.name);
                    sessionStorage.setItem("email", res.data.email);
                    sessionStorage.setItem("user_name", res.data.user_name);
                    sessionStorage.setItem("session", res.data.sesssionToken);
                    navigate("/");
                } else if (res.response.response_code === "201") {
                    setshowotp(true);
                    setVerification((prev) => ({
                        ...prev,
                        authToken: res.data.authtoken,
                    }));
                } else if (res.response.response_code === "400") {
                    setError((prv) => ({
                        ...prv,
                        password: res.response.response_message,
                        user_id: " ",
                    }));
                }
            })
            .catch((error) => {
                setLoading(false);

                console.error(error);
                setError((prv) => ({
                    ...prv,
                    password:
                        "Some Technical Issue!\n Please try after some time",
                    user_id: " ",
                }));
            });
    };
    return (
        <>
            {showotp && otpCom()}
            <section
                className="sign-up-in-section bg-dark ptb-60"
                style={{
                    background:
                        "url('assets/img/page-header-bg.svg')no-repeat right bottom",
                }}
            >
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 col-md-8 col-12">
                            <Link to="/" className="mb-4 d-block text-center">
                                <img
                                    src="assets/img/post-it.png"
                                    alt="logo"
                                    style={{ width: "40px" }}
                                    className="img-fluid"
                                />
                            </Link>
                            <div className="register-wrap p-5 bg-light shadow rounded-custom">
                                <h1 className="h3">Nice to Seeing You Again</h1>
                                <p className="text-muted">
                                    Please log in to access your account
                                    web-enabled methods of innovative niches.
                                </p>

                                <div className="action-btns">
                                    <Link
                                        to="#"
                                        className="btn google-btn bg-white shadow-sm mt-4 d-block d-flex align-items-center text-decoration-none justify-content-center"
                                        disabled
                                    >
                                        <img
                                            src="assets/img/google-icon.svg"
                                            alt="google"
                                            className="me-3"
                                        />
                                        <span>Connect with Google</span>
                                    </Link>
                                </div>
                                <div className="position-relative d-flex align-items-center justify-content-center mt-4 py-4">
                                    <span className="divider-bar"></span>
                                    <h6 className="position-absolute text-center divider-text bg-light mb-0">
                                        Or
                                    </h6>
                                </div>
                                <form
                                    action="#"
                                    className="mt-4 register-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        !loading && __handleSubmit();
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-sm-12 ">
                                            <label
                                                htmlFor="email"
                                                className="mb-1"
                                            >
                                                Username or Email{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="input-group ">
                                                <input
                                                    type="text"
                                                    className={`form-control ${
                                                        error.user_id &&
                                                        "border-danger"
                                                    } `}
                                                    placeholder="Username or Email id"
                                                    id="email"
                                                    aria-label="email"
                                                    value={userdetails.user_id}
                                                    onChange={(e) => {
                                                        setuser((prev) => ({
                                                            ...prev,
                                                            user_id:
                                                                e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            {error.user_id && (
                                                <p
                                                    style={{
                                                        fontSize: 10,
                                                        color: "red",
                                                        padding: 0,
                                                        margin: 0,
                                                    }}
                                                >
                                                    {error.user_id}
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-sm-12 mt-3 ">
                                            <label
                                                htmlFor="password"
                                                className="mb-1"
                                            >
                                                Password{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="input-group  ">
                                                <input
                                                    type="password"
                                                    className={`form-control ${
                                                        error.password &&
                                                        "border-danger"
                                                    } `}
                                                    placeholder="Password"
                                                    id="password"
                                                    aria-label="Password"
                                                    value={userdetails.password}
                                                    onChange={(e) => {
                                                        setuser((prev) => ({
                                                            ...prev,
                                                            password:
                                                                e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            {error.password && (
                                                <p
                                                    style={{
                                                        fontSize: 10,
                                                        color: "red",
                                                        padding: 0,
                                                        margin: 0,
                                                    }}
                                                >
                                                    {error.password}
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary mt-3 d-block w-100 text-center d-flex justify-content-center align-items-center"
                                            >
                                                {loading && !showotp ? (
                                                    <PacmanLoader
                                                        color="#fff"
                                                        size={13}
                                                    />
                                                ) : (
                                                    "Submit"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    <p className="font-monospace fw-medium text-center text-muted mt-3 pt-4 mb-0">
                                        Don’t have an account?{" "}
                                        <Link
                                            to="/signup"
                                            className="text-decoration-none"
                                        >
                                            Sign up Today
                                        </Link>
                                        <br />
                                        <Link
                                            to="/password-reset"
                                            className="text-decoration-none"
                                        >
                                            Forgot password
                                        </Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

    function otpCom() {
        const __handleVerification = (event) => {
            if (!verification.token.trim()) {
                return setError({
                    otp: "Fill Verification Code!",
                });
            } else {
                setError({});
            }
            setLoading(true);
            __makeEmailVerificationPostRequest(verification)
                .then((res) => {
                    console.log(res.response.response_code);
                    if (res.response.response_code == "200") {
                        setshowotp(false);
                        console.log(res);
                        localStorage.setItem("auth-token", res.data.authtoken);
                        sessionStorage.setItem("name", res.data.user_name);
                        navigate("/");
                    } else {
                        setError({
                            otp: res.response.response_message,
                        });
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                    setError({
                        otp: "Some Technical Issue!\n Please try after some time",
                    });
                });
        };
        const __handleResendVerificationCode = (event) => {
            __makeResendVerficationCodePostRequest(verification)
                .then((res) => {
                    console.log(res.response.response_code);
                    if (res.response.response_code == "200") {
                        setError({
                            success: res.response.response_message,
                        });
                        setVerification((prev) => ({
                            ...prev,
                            token: "",
                        }));
                    } else {
                        setError({
                            otp: res.response.response_message,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setError({
                        otp: "Some Technical Issue!\n Please try after some time",
                    });
                });
        };
        return (
            <div
                className="position-fixed d-flex justify-content-center align-items-center z-10"
                style={{ inset: 1, background: "rgba(255,255,255,0.2)" }}
            >
                <div className="card   bg-gradient border-0">
                    <div className="cardbody p-5 d-flex flex-column align-items-center ">
                        <span
                            onClick={() => setshowotp(false)}
                            className="   px-3 position-absolute top-0 right-0 "
                        >
                            <a href="#">
                                {" "}
                                <i className="far fa-close text-white fa-2x"></i>
                            </a>
                        </span>

                        <h2 className="  mb-5">Verification your account</h2>
                        <OTPInput
                            value={verification.token}
                            onChange={(value) => {
                                setVerification((prev) => ({
                                    ...prev,
                                    token: value,
                                }));
                            }}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                        />
                        {error?.otp && (
                            <p
                                style={{
                                    fontSize: 11,
                                    color: "red",
                                    padding: 0,
                                    margin: 0,
                                }}
                                className="mt-2"
                            >
                                {error?.otp}
                            </p>
                        )}
                        {error?.success && (
                            <p
                                style={{
                                    fontSize: 11,
                                    color: "green",
                                    padding: 0,
                                    margin: 0,
                                }}
                                className="mt-2"
                            >
                                {error?.success}
                            </p>
                        )}
                        <p className="  mt-5 mb-1 text-white">
                            Didn't receive the OTP?{" "}
                            <a
                                href="#"
                                className="text-primary"
                                onClick={__handleResendVerificationCode}
                            >
                                Resend OTP
                            </a>{" "}
                        </p>
                        <button
                            // type="submit"
                            onClick={() => __handleVerification()}
                            className="btn btn-primary mt-3 d-block w-100 text-center d-flex justify-content-center align-items-center"
                        >
                            {loading && showotp ? (
                                <PacmanLoader color="#fff" size={13} />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
};

export default LoginScreen;
