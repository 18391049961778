import React from "react";

const ProgressBar = ({ isShow, progress }) => {
    return (
        <div
            className={`position-fixed ${
                isShow ? "d-flex" : "d-none"
            } justify-content-center align-items-center   flex-column`}
            style={{ inset: 0, background: "rgba(0,0,0,0.4)", zIndex: 100 }}
        >
            <div className="card">
                <div className="card-body" style={{ width: "250px" }}>
                    <div
                        class="progress rounded-1 overflow-hidden"
                        style={{ backgroundColor: "#e9ecef" }}
                    >
                        <div
                            class="progress-bar rounded-0 bg-progree-pink"
                            style={{
                                width: progress + "%",
                            }}
                            role="progressbar"
                            aria-valuenow="20"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
