import React, { Component } from "react";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import HeroOne from "./HeroOne";
import PageMeta from "../../components/common/PageMeta";
import FooterOne from "../../layout/Footer/FooterOne";
import FooterSocial from "../../layout/Footer/FooterSocial";

class HomeSassOne extends Component {
    render() {
        return (
            <Layout>
                <PageMeta title="Quiety - Software &amp; IT Solutions HTML Template" />
                <Navbar navDark />
                <HeroOne />

                <FooterOne footerGradient />
                {/* <FooterSocial /> */}
            </Layout>
        );
    }
}

export default HomeSassOne;
