const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export function __hasAnyValue(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== "") {
            return true;
        }
    }
    return false;
}

export function __getRandomImage() {
    const myArray = [
        "assets/img/service/app-two-mockup.png",
        "assets/img/service/shield.png",
        "assets/img/service/coin.png",
        "assets/img/service/counter-lock.png",
        "assets/img/service/promotion.png",
        "assets/img/service/cta-img-1.png",
        "assets/img/service/cta-img-2.png",
        "assets/img/service/feature-tab-1.png",
        "assets/img/service/curve.png",
        "assets/img/service/pie-chart.png",
        "assets/img/service/app-development.png",
        "assets/img/service/coding.png",
        "assets/img/service/team.png",
        "assets/img/service/questions.png",
        "assets/img/service/graphic-design.png",
    ];
    if (myArray.length === 0) {
        return null;
    }
    const randomIndex = Math.floor(Math.random() * myArray.length);
    return myArray[randomIndex];
}
export function __userToken() {
    return localStorage.getItem("auth-token");
}
export function __authToken() {
    return sessionStorage.getItem("session");
}
export function __apiHeader(token) {
    return {
        "Content-Type": "application/json",
        "auth-token": token,
    };
}
export function __formatDate(date) {
    let inputDate = new Date(date);
    if (!(inputDate instanceof Date)) {
        throw new Error("Input must be a valid Date object");
    }

    const day = inputDate.getDate().toString().padStart(2, "0");

    const month = monthNames[inputDate.getMonth()];
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    const ampm = inputDate.getHours() >= 12 ? "PM" : "AM";

    const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;

    return formattedDate;
}
export function __formatDate2(date) {
    let inputDate = new Date(date);
    if (!(inputDate instanceof Date)) {
        throw new Error("Input must be a valid Date object");
    }

    const day = inputDate.getDate().toString().padStart(2, "0");

    const month = monthNames[inputDate.getMonth()];
    const year = inputDate.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
}
export function generateDateArray(startDate) {
    const currentDate = new Date();
    const dateArray = [];

    // Make sure the startDate is a valid Date object
    if (!(startDate instanceof Date) || isNaN(startDate)) {
        return dateArray;
    }

    // Clone the startDate to avoid modifying the original date
    let currentDatePointer = new Date(startDate);

    while (currentDatePointer <= currentDate) {
        dateArray.push(new Date(currentDatePointer));
        currentDatePointer.setDate(currentDatePointer.getDate() + 1); // Increment by 1 day
    }

    return dateArray;
}
