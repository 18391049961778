import React from "react";
import { Link } from "react-router-dom";

const OffCanvasMenu = () => {
    return (
        <>
            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasWithBackdrop"
            >
                <div className="offcanvas-header d-flex align-items-center mt-4">
                    <Link
                        to="/"
                        className="d-flex align-items-center mb-md-0 text-decoration-none"
                    >
                        <img
                            src="assets/img/post-it.png"
                            alt="logo"
                            className="img-fluid ps-2"
                            style={{ width: "60px" }}
                        />
                    </Link>
                    <button
                        type="button"
                        className="close-btn text-danger"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    >
                        <i className="far fa-close"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                        <li className="nav-item dropdown">
                            <Link to="/" className="nav-link">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/repositories" className="nav-link">
                                Repositories
                            </Link>
                        </li>
                        <li>
                            <Link to="/secret-keys" className="nav-link">
                                Key
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact-us" className="nav-link">
                                Contact Us
                            </Link>
                        </li>
                        <li>
                            <Link to="/app-setting" className="nav-link">
                                Setting
                            </Link>
                        </li>
                    </ul>
                    {!localStorage.getItem("auth-token") && (
                        <div className="action-btns mt-4 ps-3">
                            <Link
                                to="/login"
                                className="btn btn-outline-primary me-2"
                            >
                                Sign In
                            </Link>
                            <Link to="/signup" className="btn btn-primary">
                                Get Started
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default OffCanvasMenu;
