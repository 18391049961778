import React, { useState } from "react";
import { Link } from "react-router-dom";
import { __makeSetupOtpPutRequest } from "../../functions/apifun";
import ProgressBar from "../common/ProgressBar";
import { __hasAnyValue } from "../../functions/constantfun";

const Portfolio = () => {
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [success, setsuccess] = useState("");

    // setup otp
    const [otpSet, setotpSet] = useState({ otp: "", password: "" });
    const { otp, password } = otpSet;

    return (
        <>
            <ProgressBar isShow={loading} progress={progress} />

            <section className="portfolio bg-light ptb-120">
                <div className="container">
                    {/* <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="section-heading text-center">
                            <h2>Our Portfolio</h2>
                            <p>
                                Credibly grow premier ideas rather than
                                bricks-and-clicks strategic theme areas
                                distributed for stand-alone web-readiness.
                            </p>
                        </div>
                    </div>
                </div> */}
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="tab-button mb-5">
                                <ul
                                    className="nav nav-pills d-flex justify-content-center"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link active"
                                            id="pills-all-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-all"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-all"
                                            aria-selected="true"
                                        >
                                            Profile
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link"
                                            id="pills-branding-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-branding"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-branding"
                                            aria-selected="false"
                                        >
                                            Screen Lock
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-all"
                                role="tabpanel"
                                aria-labelledby="pills-all-tab"
                            >
                                {profileCom()}
                            </div>

                            <div
                                className="tab-pane fade "
                                id="pills-branding"
                                role="tabpanel"
                                aria-labelledby="pills-branding-tab"
                            >
                                {otpSetting()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

    function otpSetting(params) {
        const __handleSetupOtp = () => {
            let alertdata = { otp: "", password: "" };

            if (!otp) {
                alertdata.otp = "Please enter lock password...";
            } else if (otp.length !== 4) {
                alertdata.otp = "NOTE: Lock Password length must be 4 digit...";
            }
            if (!password) {
                alertdata.password =
                    "Please enter your notebook login password...";
            }
            setError((prv) => ({
                ...prv,
                ...alertdata,
            }));

            if (__hasAnyValue(alertdata)) {
                return;
            }
            setLoading(true);

            __makeSetupOtpPutRequest(otpSet)
                .then((res) => {
                    console.log(res);
                    setProgress(80);
                    if (res.response.response_code == "200") {
                        setsuccess("Lock Password set successfully...");
                    } else {
                        setError((prv) => ({
                            ...prv,
                            otp: " ",
                            password: res.response.response_message,
                        }));
                    }
                    setProgress(100);
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                })
                .catch((error) => {
                    setProgress(100);
                    console.error(error);
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                });
        };
        return (
            <>
                <div className="">
                    <div className="card bg-gradient">
                        <div className="cardbody d-flex align-items-center ">
                            <img
                                src={"assets/img/team/2.png"}
                                alt="user "
                                className="img-fluid mx-0 mx-lg-5 my-2"
                                width={140}
                                style={{ cursor: "pointer" }}
                            />
                            <h2 className="text-uppercase">
                                {sessionStorage.getItem("name")}
                            </h2>
                        </div>
                    </div>
                    <h4 className="text-uppercase mt-3">Setup Lock Password</h4>
                    <div className="row ">
                        <div className="col-md-4  ">
                            <div className="input-group ">
                                <input
                                    type="text"
                                    className={`form-control ${
                                        error.otp && "border-danger"
                                    } `}
                                    placeholder="New Lock Password"
                                    id="otp"
                                    aria-label="otp"
                                    value={otp}
                                    length={4}
                                    onChange={(e) => {
                                        let num = e.target.value;
                                        console.log(typeof num);
                                        console.log(/[0-9]/.test(num));
                                        if (
                                            (/[0-9]/.test(num) &&
                                                num.length < 5) ||
                                            num.length == 0
                                        ) {
                                            setotpSet((prev) => ({
                                                ...prev,
                                                otp: num,
                                            }));
                                        }
                                    }}
                                />
                            </div>
                            {error.otp ? (
                                <p
                                    style={{
                                        fontSize: 10,
                                        color: "red",
                                        padding: 0,
                                        margin: 0,
                                        fontWeight: "900",
                                    }}
                                >
                                    {error.otp}
                                </p>
                            ) : (
                                <p
                                    style={{
                                        fontSize: 10,
                                        padding: 0,
                                        margin: 0,
                                        fontWeight: "900",
                                    }}
                                >
                                    NOTE: Lock Password length must be 4
                                    digit...
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4">
                            <div className="input-group ">
                                <input
                                    type="password"
                                    className={`form-control ${
                                        error.password && "border-danger"
                                    } `}
                                    placeholder="Your Notebook Password ..."
                                    id="password"
                                    aria-label="password"
                                    value={password}
                                    onChange={(e) => {
                                        setotpSet((prev) => ({
                                            ...prev,
                                            password: e.target.value,
                                        }));
                                    }}
                                />
                            </div>
                            {error.password && (
                                <p
                                    style={{
                                        fontSize: 10,
                                        color: "red",
                                        padding: 0,
                                        margin: 0,
                                        fontWeight: "900",
                                    }}
                                >
                                    {error.password}
                                </p>
                            )}
                            {success && (
                                <p
                                    style={{
                                        fontSize: 10,
                                        color: "green",
                                        padding: 0,
                                        margin: 0,
                                        fontWeight: "900",
                                    }}
                                >
                                    {success}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className=" col-md-4 text-lg-end text-center mt-3 mt-lg-0 ">
                            <button
                                className="btn bg-pink py-1 px-4 mb-3 "
                                style={{
                                    fontSize: 12,
                                }}
                                onClick={() => __handleSetupOtp()}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function profileCom(params) {
        return (
            <div className=" ">
                <div className="card bg-gradient">
                    <div className="cardbody d-flex align-items-center ">
                        <img
                            src={"assets/img/team/2.png"}
                            alt="user "
                            className="img-fluid mx-0 mx-lg-5 my-2"
                            width={140}
                            style={{ cursor: "pointer" }}
                        />
                        <h2 className="text-uppercase">
                            {sessionStorage.getItem("name")}
                        </h2>
                    </div>
                </div>
                <div className="row  py-4">
                    <div className=" col-md-4  ">
                        <label htmlFor="otp">Name</label>

                        <div className="input-group ">
                            <input
                                type="text"
                                className={`form-control  `}
                                placeholder="Your Name"
                                id="otp"
                                aria-label="otp"
                                disabled
                                value={sessionStorage.getItem("name")}
                            />
                        </div>
                    </div>
                    <div className=" col-md-4  ">
                        <label htmlFor="otp"> Email</label>

                        <div className="input-group ">
                            <input
                                type="text"
                                className={`form-control  `}
                                placeholder="Email ID"
                                id="otp"
                                aria-label="otp"
                                disabled
                                value={sessionStorage.getItem("email")}
                            />
                        </div>
                    </div>
                    <div className=" col-md-4  ">
                        <label htmlFor="otp"> User Name</label>

                        <div className="input-group ">
                            <input
                                type="text"
                                className={`form-control  `}
                                placeholder="User Name"
                                id="otp"
                                aria-label="otp"
                                disabled
                                value={sessionStorage.getItem("user_name")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Portfolio;
