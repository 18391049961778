import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import axios from "axios";
import api from "../api";
import { GET_PRODUCT_LIST, SET_PRODUCT_LIST } from "../constants";

function* getProducts(action) {
  try {
    yield put({
      type: SET_PRODUCT_LIST,
      payload: { loading: true, data: null },
    });
    const response = yield call(
      axios.get,
      api.PRODUCTS_API + "/" + action.payload.type
    );
    console.log(response.data);
    let data = {
      loading: false,
      data: response.data,
    };
    yield put({ type: SET_PRODUCT_LIST, payload: data });
  } catch (error) {
    yield put({ type: "POST_DATA_ERROR", error });
  }
}
function* homeSaga() {
  yield takeEvery(GET_PRODUCT_LIST, getProducts);
}
export default homeSaga;
