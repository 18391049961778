import {
  ADD_PRODUCT_LIST,
  REMOVE_PRODUCT_LIST,
  SET_PRODUCT_LIST,
  UPDATE_TOTAL,
  SET_ORDER_LIST,
} from "../constants";

const initialState = {
  product_list: {
    loading: true,
    data: [],
    error: null,
  },

  order_list: [],
  total_amount: 0,
};

const findProductIndex = (products, id) => {
  return products.findIndex((product) => product.id === id);
};
export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_LIST:
      return {
        ...state,
        product_list: action.payload,
      };
    case ADD_PRODUCT_LIST:
      const existingProductIndex = findProductIndex(
        state.order_list,
        action.payload.id
      );

      if (existingProductIndex !== -1) {
        const updatedProducts = state.order_list.map((product, index) => {
          if (index === existingProductIndex) {
            return { ...product, count: product.count + 1 };
          }
          return product;
        });

        return {
          ...state,
          order_list: updatedProducts,
        };
      } else {
        return {
          ...state,
          order_list: [...state.order_list, { ...action.payload, count: 1 }],
        };
      }

    case REMOVE_PRODUCT_LIST:
      const existingProductIndex1 = findProductIndex(
        state.order_list,
        action.payload.id
      );

      if (existingProductIndex1 !== -1) {
        const updatedProducts = state.order_list.map((product, index) => {
          if (index === existingProductIndex1) {
            const updatedCount = product.count - 1;
            if (updatedCount <= 0) {
              return null;
            }
            return { ...product, count: updatedCount };
          }
          return product;
        });
        console.log(updatedProducts);
        return {
          ...state,
          order_list: updatedProducts.filter((product) => product !== null),
        };
      } else {
        return state;
      }

    case UPDATE_TOTAL:
      const newTotal = state.order_list.reduce(
        (total, product) => total + product.price * product.count,
        0
      );
      return {
        ...state,
        total_amount: newTotal,
      };

    case SET_ORDER_LIST:
      console.log(action.payload);
      return {
        ...state,
        order_list: action.payload,
      };
    default:
      return state;
  }
};
