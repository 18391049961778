import axios from "axios";
import { __apiHeader, __authToken, __userToken } from "./constantfun";

const __LIVE = process.env.REACT_APP_LIVE_HOST;
const __LOCAL = process.env.REACT_APP_LOCAL_HOST;

const __HOST = () => {
    if (process.env.NODE_ENV === "development") {
        return __LOCAL;
    } else {
        return __LIVE;
    }
};

export function __makeGetSessionPostRequest(apidata) {
    const url = __HOST() + "/api/auth/get_session";
    const headers = __apiHeader(__userToken());

    return axios
        .post(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeGetUserGetRequest() {
    const url = __HOST() + "/api/auth/getuser";
    const headers = __apiHeader(__userToken());
    return axios
        .get(url, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeSignupPostRequest(apidata) {
    const url = __HOST() + "/api/auth/createuser";

    return axios
        .post(url, apidata)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeEmailVerificationPostRequest(apidata) {
    const url = __HOST() + "/api/auth/verification";
    const headers = __apiHeader(apidata?.authToken);

    return axios
        .post(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeResendVerficationCodePostRequest(apidata) {
    const url = __HOST() + "/api/auth/resend_otp";
    const headers = __apiHeader(apidata?.authToken);

    return axios
        .post(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeLoginPostRequest(apidata) {
    const url = __HOST() + "/api/auth/login";
    const headers = __apiHeader(apidata?.authToken);

    return axios
        .post(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export function __makeCreateRepoPostRequest(apidata) {
    const url = __HOST() + "/api/repo/create_repo";
    const headers = __apiHeader(__authToken());

    return axios
        .post(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export function __makeGetRepoGetRequest() {
    const url = __HOST() + "/api/repo/fetch_repo";
    const headers = __apiHeader(__authToken());

    return axios
        .get(url, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeGetRepoDetailsGetRequest(_id) {
    const url = __HOST() + "/api/notes/get_repo_details/" + _id;
    const headers = __apiHeader(__authToken());

    return axios
        .get(url, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeCreateNotePostRequest(apidata) {
    const url = __HOST() + "/api/notes/addnote";
    const headers = __apiHeader(__authToken());

    return axios
        .post(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeDeleteNoteDeleteRequest(_id) {
    const url = __HOST() + "/api/notes/deletenote/" + _id;
    const headers = __apiHeader(__authToken());

    return axios
        .delete(url, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeDeleteRepoDeleteRequest(_id) {
    const url = __HOST() + "/api/repo/delete_repo/" + _id;
    const headers = __apiHeader(__authToken());

    return axios
        .delete(url, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export function __makeUpdateNotePutRequest(apidata, _id) {
    const url = __HOST() + "/api/notes/updatenote/" + _id;
    const headers = __apiHeader(__authToken());

    return axios
        .put(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export function __makeCreateSecretPostRequest(apidata) {
    const url = __HOST() + "/api/key/create_secret";
    const headers = __apiHeader(__authToken());

    return axios
        .post(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export function __makeGetKeyGetRequest() {
    const url = __HOST() + "/api/key/get_secret";
    const headers = __apiHeader(__authToken());

    return axios
        .get(url, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeGetSecretKeyGetRequest(_id) {
    const url = __HOST() + "/api/key/get_secret/" + _id;
    const headers = __apiHeader(__authToken());

    return axios
        .get(url, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeDeleteSecretKeyDeleteRequest(_id) {
    const url = __HOST() + "/api/key/delete_secret/" + _id;
    const headers = __apiHeader(__authToken());

    return axios
        .delete(url, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export function __makeSecretKeyPutRequest(apidata, _id) {
    const url = __HOST() + "/api/key/update_secret/" + _id;
    const headers = __apiHeader(__authToken());

    return axios
        .put(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
export function __makeSetupOtpPutRequest(apidata) {
    const url = __HOST() + "/api/auth/setup/otp";
    const headers = __apiHeader(__authToken());

    return axios
        .put(url, apidata, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}
