import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    __makeEmailVerificationPostRequest,
    __makeResendVerficationCodePostRequest,
    __makeSignupPostRequest,
} from "../../functions/apifun";
import { __hasAnyValue } from "../../functions/constantfun";
import OTPInput, { ResendOTP } from "otp-input-react";
import { PacmanLoader } from "react-spinners";
const SignUpForm = () => {
    let navigate = useNavigate();
    const [userdetails, setuser] = useState({
        name: "",
        email: "",
        password: "",
        user_name: "",
    });
    const [showotp, setshowotp] = useState(false);
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState({});
    const [verification, setVerification] = useState({
        token: "",
        authToken: "",
    });

    const __handleSubmit = (event) => {
        let alertdata = { name: "", email: "", password: "", user_name: "" };

        if (!userdetails.name.trim()) {
            alertdata.name = "Add your name";
        }
        if (!userdetails.email.trim()) {
            alertdata.email = "Add your Email address";
        }
        if (!userdetails.password) {
            alertdata.password = "Add your Password";
        }
        if (!userdetails.user_name.trim()) {
            alertdata.user_name = "Add your Username";
        }
        setError((prv) => ({
            ...prv,
            ...alertdata,
        }));

        if (__hasAnyValue(alertdata)) {
            return;
        }
        setLoading(true);

        __makeSignupPostRequest(userdetails)
            .then((res) => {
                console.log(res);
                if (res.response.response_code === "200") {
                    setshowotp(true);
                    setVerification((prev) => ({
                        ...prev,
                        authToken: res.data.authtoken,
                    }));
                } else if (res.response.response_code === "406") {
                    let fieldError = {};
                    res.data.error.forEach((element) => {
                        fieldError[element.param] = element.msg;
                    });
                    setError((prv) => ({
                        ...prv,
                        ...fieldError,
                    }));
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setError((prv) => ({
                    ...prv,
                    password:
                        "Some Technical Issue!\n Please try after some time",
                    user_name: " ",
                    name: " ",
                    email: " ",
                }));
            });
    };

    return (
        <>
            {showotp && otpCom()}
            <div className="price-feature-col pricing-action-info p-5 right-radius bg-light order-0 order-lg-1">
                <Link to="/" className="mb-5 d-block d-xl-none d-lg-none">
                    <img
                        src="assets/img/post-it.png"
                        alt="logo"
                        style={{ width: "40px" }}
                        className="img-fluid"
                    />
                </Link>
                <h1 className="h3">Create an Account</h1>
                <p className="text-muted">
                    Get started with your free account today. No credit card
                    needed and no setup fees.
                </p>

                <form
                    action="#"
                    className="mt-5 register-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        __handleSubmit();
                    }}
                >
                    <div className="row">
                        <div className="col-sm-6">
                            <label htmlFor="name" className="mb-1">
                                Name <span className="text-danger">*</span>
                            </label>
                            <div className="input-group ">
                                <input
                                    type="text"
                                    className={`form-control ${
                                        error.name && "border-danger"
                                    } `}
                                    placeholder="Name"
                                    // id="name"
                                    aria-label="name"
                                    value={userdetails?.name}
                                    onChange={(e) => {
                                        setuser((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }));
                                    }}
                                />
                            </div>
                            {error.name && (
                                <p
                                    style={{
                                        fontSize: 10,
                                        color: "red",
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    {error.name}
                                </p>
                            )}
                        </div>
                        <div className="col-sm-6 ">
                            <label htmlFor="username" className="mb-1">
                                Username <span className="text-danger">*</span>
                            </label>
                            <div className="input-group  ">
                                <input
                                    type="text"
                                    className={`form-control ${
                                        error.user_name && "border-danger"
                                    } `}
                                    placeholder="Username"
                                    // id="username"
                                    aria-label="username"
                                    value={userdetails?.user_name}
                                    onChange={(e) => {
                                        setuser((prev) => ({
                                            ...prev,
                                            user_name: e.target.value,
                                        }));
                                    }}
                                />
                            </div>
                            {error.user_name && (
                                <p
                                    style={{
                                        fontSize: 10,
                                        color: "red",
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    {error.user_name}
                                </p>
                            )}
                        </div>
                        <div className="col-sm-12 mt-3">
                            <label htmlFor="email" className="mb-1">
                                Email <span className="text-danger">*</span>
                            </label>
                            <div className="input-group  ">
                                <input
                                    type="email"
                                    className={`form-control ${
                                        error.email && "border-danger"
                                    } `}
                                    placeholder="Email"
                                    // id="email"
                                    aria-label="email"
                                    value={userdetails?.email}
                                    onChange={(e) => {
                                        setuser((prev) => ({
                                            ...prev,
                                            email: e.target.value,
                                        }));
                                    }}
                                />
                            </div>
                            {error.email && (
                                <p
                                    style={{
                                        fontSize: 10,
                                        color: "red",
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    {error.email}
                                </p>
                            )}
                        </div>
                        <div className="col-sm-12 mt-3">
                            <label htmlFor="password" className="mb-1">
                                Password <span className="text-danger">*</span>
                            </label>
                            <div className="input-group ">
                                <input
                                    type="password"
                                    className={`form-control ${
                                        error.password && "border-danger"
                                    } `}
                                    placeholder="Password"
                                    // id="password"
                                    aria-label="Password"
                                    value={userdetails?.password}
                                    onChange={(e) => {
                                        setuser((prev) => ({
                                            ...prev,
                                            password: e.target.value,
                                        }));
                                    }}
                                />
                            </div>
                            {error.password && (
                                <p
                                    style={{
                                        fontSize: 10,
                                        color: "red",
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    {error.password}
                                </p>
                            )}
                        </div>
                        <div className="col-12 mt-3">
                            <div className="form-check d-flex">
                                <input
                                    className="form-check-input me-2"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked"
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="flexCheckChecked"
                                >
                                    I have read and agree to the{" "}
                                    <Link
                                        to="#"
                                        className="text-decoration-none"
                                    >
                                        Terms & Conditions
                                    </Link>
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button
                                type="submit"
                                className="btn btn-primary mt-3 d-block w-100 text-center d-flex justify-content-center align-items-center"
                            >
                                {loading && !showotp ? (
                                    <PacmanLoader color="#fff" size={13} />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="position-relative d-flex align-items-center justify-content-center mt-4 py-4">
                        <span className="divider-bar"></span>
                        <h6 className="position-absolute text-center divider-text bg-light mb-0">
                            Or
                        </h6>
                    </div>
                    <div className="action-btns">
                        <Link
                            to="#"
                            className="btn google-btn mt-4 d-block bg-white shadow-sm d-flex align-items-center text-decoration-none justify-content-center"
                        >
                            <img
                                src="assets/img/google-icon.svg"
                                alt="google"
                                className="me-3"
                            />
                            <span>Sign up with Google</span>
                        </Link>
                    </div>
                    <p className="text-center text-muted mt-4 mb-0 fw-medium font-monospace">
                        Already have an account?{" "}
                        <Link to="/login" className="text-decoration-none">
                            Sign in
                        </Link>
                    </p>
                </form>
            </div>
        </>
    );

    function otpCom() {
        const __handleVerification = (event) => {
            if (!verification.token.trim()) {
                return setError({
                    otp: "Fill Verification Code!",
                });
            } else {
                setError({});
            }
            setLoading(true);
            __makeEmailVerificationPostRequest(verification)
                .then((res) => {
                    console.log(res.response.response_code);
                    if (res.response.response_code == "200") {
                        setshowotp(false);
                        localStorage.setItem("auth-token", res.data.authtoken);
                        sessionStorage.setItem("name", res.data.user_name);

                        navigate("/");
                    } else {
                        setError({
                            otp: res.response.response_message,
                        });
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setError({
                        otp: "Some Technical Issue!\n Please try after some time",
                    });
                });
        };
        const __handleResendVerificationCode = (event) => {
            __makeResendVerficationCodePostRequest(verification)
                .then((res) => {
                    if (res.response.response_code == "200") {
                        setError({
                            success: res.response.response_message,
                        });
                        setVerification((prev) => ({
                            ...prev,
                            token: "",
                        }));
                    } else {
                        setError({
                            otp: res.response.response_message,
                        });
                    }
                })
                .catch((error) => {
                    setError({
                        otp: "Some Technical Issue!\n Please try after some time",
                    });
                });
        };
        return (
            <div
                className="position-fixed d-flex justify-content-center align-items-center z-10"
                style={{ inset: 1, background: "rgba(255,255,255,0.2)" }}
            >
                <div className="card   bg-gradient border-0">
                    <div className="cardbody p-5 d-flex flex-column align-items-center ">
                        <span
                            onClick={() => setshowotp(false)}
                            className="   px-3 position-absolute top-0 right-0 "
                        >
                            <a href="#">
                                <i className="far fa-close text-white fa-2x"></i>
                            </a>
                        </span>

                        <h2 className="  mb-5">Verification your account</h2>
                        <OTPInput
                            value={verification.token}
                            onChange={(value) => {
                                setVerification((prev) => ({
                                    ...prev,
                                    token: value,
                                }));
                            }}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                        />
                        {error?.otp && (
                            <p
                                style={{
                                    fontSize: 11,
                                    color: "red",
                                    padding: 0,
                                    margin: 0,
                                }}
                                className="mt-2"
                            >
                                {error?.otp}
                            </p>
                        )}
                        {error?.success && (
                            <p
                                style={{
                                    fontSize: 11,
                                    color: "green",
                                    padding: 0,
                                    margin: 0,
                                }}
                                className="mt-2"
                            >
                                {error?.success}
                            </p>
                        )}
                        <p className="  mt-5 mb-1 text-white">
                            Didn't receive the OTP?{" "}
                            <a
                                href="#"
                                className="text-primary"
                                onClick={__handleResendVerificationCode}
                            >
                                Resend OTP
                            </a>{" "}
                        </p>

                        <button
                            // type="submit"
                            onClick={() => __handleVerification()}
                            className="btn btn-primary mt-3 d-block w-100 text-center d-flex justify-content-center align-items-center"
                        >
                            {loading && showotp ? (
                                <PacmanLoader color="#fff" size={13} />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
};

export default SignUpForm;
