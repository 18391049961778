import React from "react";
import { __formatDate } from "../../functions/constantfun";

export default function EventCounter({ repoDetails }) {
    const urlParams = new URLSearchParams(window.location.search);

    return (
        <section
            className="ev-counter pt-100"
            style={{
                background:
                    "url('assets/img/ev-counter-bg.jpg')no-repeat center center/ cover",
            }}
        >
            <div className="container ">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="text-center ">
                            <div className=" pb-60"></div>
                            <h2 className="text-white mb-4">
                                {repoDetails?.repo_name
                                    ? repoDetails.repo_name
                                    : urlParams.get("n")}
                            </h2>
                            {repoDetails && (
                                <div className="d-flex justify-content-center mb-2">
                                    <div className="date pe-4 small text-muted">
                                        <i className="far fa-alarm-clock pe-2"></i>
                                        <span>
                                            {repoDetails?.createdAt &&
                                                __formatDate(
                                                    repoDetails?.createdAt
                                                )}
                                        </span>
                                    </div>
                                    <div className="location small text-muted">
                                        <i className="far fa-map-marker-alt pe-2"></i>
                                        <span>Time Square , India</span>
                                    </div>
                                </div>
                            )}
                            {/* <span className="fw-bold text-white">
                                Conference Countdown
                            </span>
                            <h2 className="text-white mb-4">
                                Never Miss Another Speaker Announcement
                            </h2>
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="ev-count-down">
                                        <div className="ev-cd-box">
                                            <h4 className="h3 mb-0">36</h4>
                                            <span className="small">Day</span>
                                        </div>
                                        <div className="ev-cd-box">
                                            <h4 className="h3 mb-0">19</h4>
                                            <span className="small">Hours</span>
                                        </div>
                                        <div className="ev-cd-box">
                                            <h4 className="h3 mb-0">48</h4>
                                            <span className="small">
                                                Minutes
                                            </span>
                                        </div>
                                        <div className="ev-cd-box">
                                            <h4 className="h3 mb-0">16</h4>
                                            <span className="small">
                                                Seconds
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
