import React from "react";
import PageMeta from "../../components/common/PageMeta";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import HeroTen from "./HeroTen";
import FooterOne from "../../layout/Footer/FooterOne";

const HomeTen = () => {
    return (
        <Layout>
            <PageMeta title="Cyber Security- Software &amp; IT Solutions HTML Template" />
            <Navbar navDark />
            <HeroTen />
            <FooterOne />
        </Layout>
    );
};

export default HomeTen;
