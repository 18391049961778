import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { __formatDate, __formatDate2 } from "../../functions/constantfun";
import { PacmanLoader } from "react-spinners";
import { __makeUpdateNotePutRequest } from "../../functions/apifun";
import ProgressBar from "../common/ProgressBar";

const NoteCom = ({ item, repoName, __handleDeleteNotes }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const [textareaHeight, setTextareaHeight] = useState(0);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [isEdit, setEditButton] = useState(false);
    const [summery, setSummery] = useState("");
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(null);

    const __textareaHeight = () =>
        document?.getElementById(`yourMessage${item._id}`)?.scrollHeight;

    const __handleTextareaHeight = (event) => {
        let height = __textareaHeight();
        setTextareaHeight(height > 200 ? height + 10 : 200);
        setShowMoreButton(false);
    };

    const __handleDelete = () => {
        // info: Conformation Alert
        __handleDeleteNotes(item._id);
    };
    const __handleUpdateNotes = () => {
        setLoading(true);
        setProgress(10);
        setTimeout(() => {
            setProgress(50);
        }, 10);
        __makeUpdateNotePutRequest({ note_summary: summery }, item._id)
            .then((res) => {
                console.log(res);
                setProgress(80);

                if (res.response.response_code === "200") {
                    setEditButton(false);
                }
                setProgress(100);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    };

    const __handleCopyClick = () => {
        const textarea = document.getElementById(`yourMessage${item._id}`);
        textarea.select();
        document.execCommand("copy");
    };

    useEffect(() => {
        if (summery) {
            let height = __textareaHeight();
            console.log(height);
            console.log(height > 200 && true);
            setTextareaHeight(height > 200 ? 200 : height + 5);
            setShowMoreButton(height > 200 && true);
        }
    }, [summery]);

    useEffect(() => {
        item?.note_summary && setSummery(item.note_summary);
    }, [item]);

    return (
        <>
            <ProgressBar isShow={loading} progress={progress} />
            <div className="single-eve-session py-5 rounded px-4 px-lg-0 mb-4">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className=" speaker-info mb-3 mx-0 mx-lg-4 mb-lg-0 py-3 md-py-0">
                            <h5
                                className="h6 m-0 "
                                style={{
                                    textAlign: "justify",
                                }}
                            >
                                {item.note_query}
                            </h5>
                            <div className="d-md-flex mb-2">
                                <div className="date pe-4 small text-muted">
                                    <i className="far fa-alarm-clock pe-2"></i>
                                    <span>{__formatDate(item.createdAt)}</span>
                                </div>
                                <div className="location small text-muted">
                                    <i className="far fa-map-marker-alt pe-2"></i>
                                    <span>Time Square , India</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="single-ev px-lg-4 mt-3 ">
                            <div className="d-flex justify-content-end align-items-center gap-2">
                                <button
                                    onClick={() => setEditButton(true)}
                                    class="btn bg-pink py-1 px-4 mb-3"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </button>
                                <button
                                    onClick={__handleDelete}
                                    class="btn bg-pink py-1 px-4 mb-3"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    <i class="fa-sharp fa-solid fa-trash"></i>
                                </button>
                                <button
                                    onClick={__handleCopyClick}
                                    class="btn bg-pink py-1 px-4 mb-3"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    COPY
                                </button>
                            </div>
                            {/* <code>
                            <p className="mb-3">
                                Authoritatively orchestrate performance based
                                human capital for high standards in materials.
                                Interactively fabricate timely ideas after
                                premier opportunitiesObjectively based human
                                capital implement.
                            </p>
                        </code> */}

                            <div className="input-group mb-3">
                                <textarea
                                    className="form-control  "
                                    id={`yourMessage${item._id}`}
                                    placeholder="How can we help you?"
                                    style={{
                                        height: textareaHeight + "px",
                                        fontWeight: "600",
                                        letterSpacing: "1px",
                                    }}
                                    value={summery}
                                    onChange={(e) => setSummery(e.target.value)}
                                    readOnly={!isEdit}
                                ></textarea>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-6 text-lg-start text-center">
                                    {showMoreButton && (
                                        <span
                                            className="link-with-icon"
                                            onClick={() =>
                                                __handleTextareaHeight()
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            More Information
                                            <i className="far fa-arrow-right"></i>
                                        </span>
                                    )}
                                </div>
                                <div className="col-12 col-sm-6 col-lg-6 text-lg-end text-center mt-3 mt-lg-0 ">
                                    {isEdit && (
                                        <button
                                            className="btn bg-pink py-1 px-4 mb-3 "
                                            style={{
                                                fontSize: 12,
                                            }}
                                            onClick={() =>
                                                __handleUpdateNotes()
                                            }
                                        >
                                            Save
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default function EventSession({
    repoNotes,
    repoName,
    repoCreateAt,
    __handleDeleteNotes,
}) {
    return (
        <section>
            <div className="container ">
                <div className="ev-schedule  mb-5 ptb-100 ptb-event-100 rounded mt--50">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="text-center ev-schedule-btn pb-1 pb-md-5  pb-lg-5 ">
                                {repoCreateAt && (
                                    <span className="text-pink fw-bold">
                                        {__formatDate2(repoCreateAt)} to{" "}
                                        {__formatDate2(new Date())}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="pills-day1"
                                    role="tabpanel"
                                    aria-labelledby="pills-home-tab"
                                >
                                    {repoNotes ? (
                                        repoNotes?.map((item, i) => (
                                            <NoteCom
                                                key={i}
                                                item={item}
                                                repoName={repoName}
                                                __handleDeleteNotes={
                                                    __handleDeleteNotes
                                                }
                                            />
                                        ))
                                    ) : (
                                        <div className="d-flex justify-content-center py-5 py-sm-1">
                                            <PacmanLoader
                                                color="#fd035a"
                                                size={20}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {repoNotes && (
                        <div className="col-12 ">
                            <div className="error-page-content-wrap d-flex flex-column justify-content-center align-items-center">
                                {repoNotes.length == 0 && (
                                    <div className="d-flex justify-content-center">
                                        <div className="col-10 col-lg-6 col-md-8 ">
                                            <img
                                                src="assets/img/notfound_img.png"
                                                alt=""
                                                width={"100%"}
                                            />
                                        </div>
                                    </div>
                                )}
                                <h1 className="display-6 fw-bold text-center mt-2">
                                    {repoNotes.length == 0 &&
                                        "Create Your First Note..."}
                                </h1>
                            </div>
                        </div>
                    )}
                    <ul className="circel-shape list-unstyled d-none d-lg-block mb-0">
                        <li></li>
                        <li></li>
                        <li></li>
                        {/* <li></li>
                        <li></li>
                        <li></li> */}
                    </ul>
                </div>
            </div>
        </section>
    );
}
