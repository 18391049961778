import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";

const Navbar = ({ navDark }) => {
    let navigate = useNavigate();
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    useEffect(() => {
        const stickyheader = document.querySelector(".main-header");
        setHeaderTop(stickyheader.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    return (
        <>
            <header
                className={`main-header ${
                    navDark ? "position-absolute " : ""
                } w-100 position-absolute `}
            >
                <nav
                    className={`navbar navbar-expand-xl z-10 ${
                        navDark ? "navbar-dark" : "navbar-light"
                    } sticky-header ${scroll > headerTop ? "affix" : ""}`}
                >
                    <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <Link
                            to="/"
                            className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
                        >
                            <img
                                src="assets/img/post-it.png"
                                alt="logo"
                                className="img-fluid logo-white"
                                style={{ width: "40px" }}
                            />
                            <img
                                src="assets/img/logo/light.png"
                                alt="logo"
                                className="img-fluid logo-white"
                                style={{ width: "140px" }}
                            />
                            <img
                                src="assets/img/post-it.png"
                                alt="logo"
                                className="img-fluid logo-color"
                                style={{ width: "40px" }}
                            />
                            <img
                                src="assets/img/logo/dark.png"
                                alt="logo"
                                className="img-fluid logo-color"
                                style={{ width: "140px" }}
                            />
                            {/* <span className="logo-white">NoteBook</span> */}
                            {/* <span className="logo-color">NoteBook</span> */}
                        </Link>
                        <Link
                            className="navbar-toggler position-absolute right-0 border-0"
                            to="#offcanvasWithBackdrop"
                            role="button"
                        >
                            <span
                                className="far fa-bars"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasWithBackdrop"
                                aria-controls="offcanvasWithBackdrop"
                            ></span>
                        </Link>

                        <div className="collapse navbar-collapse justify-content-center flex-grow-0">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                                <li>
                                    <Link className="nav-link" to="/">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/repositories"
                                        className="nav-link"
                                    >
                                        Repositories
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/secret-keys"
                                        className="nav-link"
                                    >
                                        Key
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/about-us" className="nav-link">
                                        About
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/contact-us" className="nav-link">
                                        Contact Us
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/app-setting"
                                        className="nav-link"
                                    >
                                        Setting
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        {localStorage.getItem("auth-token") ? (
                            <div className="collapse navbar-collapse justify-content-end align-items-center  flex-grow-0 ">
                                <ul className="nav col-12 col-md-auto justify-content-center align-items-center main-menu  ">
                                    <li>
                                        <img
                                            src="assets/img/team/2.png"
                                            alt="user"
                                            className="img-fluid"
                                            width={50}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </li>
                                    <li>
                                        <Link to="#" className="nav-link">
                                            {sessionStorage.getItem(
                                                "user_name"
                                            )}
                                        </Link>
                                    </li>
                                    <li>
                                        <span
                                            onClick={() => {
                                                localStorage.clear();
                                                navigate("/login");
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <i class="fa-regular fa-right-from-bracket text-pink"></i>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        ) : (
                            <>
                                <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                                    <Link
                                        to="/login"
                                        className="btn btn-link text-decoration-none me-2"
                                    >
                                        Sign In
                                    </Link>
                                    <Link
                                        to="/signup"
                                        className="btn btn-primary"
                                    >
                                        Get Started
                                    </Link>
                                </div>
                            </>
                        )}
                        <OffCanvasMenu />
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Navbar;
