import React, { useEffect, useState } from "react";
import {
    __authToken,
    __getRandomImage,
    __hasAnyValue,
    __userToken,
} from "../../functions/constantfun";
import {
    __makeCreateRepoPostRequest,
    __makeCreateSecretPostRequest,
    __makeGetKeyGetRequest,
    __makeGetSecretKeyGetRequest,
    __makeDeleteSecretKeyDeleteRequest,
    __makeSecretKeyPutRequest,
} from "../../functions/apifun";
import { PacmanLoader } from "react-spinners";
import SomeError from "../common/SomeError";
import ProgressBar from "../common/ProgressBar";
import { useNavigate } from "react-router-dom";

const SecretKeyCom = React.memo(({ item, __handleDeleteKey }) => {
    const [error, setError] = useState({});
    const [showKey, setshowKey] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [key, setkey] = useState(null);
    const [progress, setProgress] = useState(null);

    const __handleGetData = () => {
        setLoading(true);
        setProgress(10);
        setTimeout(() => {
            setProgress(50);
        }, 10);

        __makeGetSecretKeyGetRequest(item._id)
            .then((res) => {
                setProgress(80);
                if (res.response.response_code == "200") {
                    setkey(res.data.key);
                } else {
                }
                setProgress(100);
                setTimeout(() => {
                    setLoading(false);
                    setTimeout(() => {
                        setshowKey(true);
                    }, 50);
                }, 500);
            })
            .catch((error) => {
                setProgress(100);
                console.error(error);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    };
    const __handleCopyClick = () => {
        const textarea = document.getElementById(`yourkey${item._id}`);
        console.log(textarea);
        textarea.select();
        document.execCommand("copy");
    };

    const __handleUpdate = () => {
        setLoading(true);
        setProgress(10);
        setTimeout(() => {
            setProgress(50);
        }, 10);

        __makeSecretKeyPutRequest({ key }, item._id)
            .then((res) => {
                console.log(res);
                setProgress(80);
                if (res.response.response_code == "200") {
                    setIsEdit(false);
                }
                setProgress(100);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            })
            .catch((error) => {
                setProgress(100);
                console.error(error);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    };
    return (
        <>
            <ProgressBar isShow={loading} progress={progress} />

            {showKey && secretKey()}
            <div className="col-lg-4 col-md-6 mt-4 ">
                <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                    <span className="fad fa-comment-alt-lines fa-3x text-primary"></span>
                    <div className="contact-promo-info mb-4">
                        <h5>{item.key_name}</h5>
                    </div>
                    <button
                        className="btn btn-link mt-auto"
                        onClick={() => {
                            __handleGetData();
                        }}
                    >
                        View key!
                    </button>
                </div>
            </div>
        </>
    );
    function secretKey() {
        return (
            <div
                className="position-fixed d-flex justify-content-center align-items-center z-10"
                style={{ inset: 1, background: "rgba(255,255,255,0.2)" }}
            >
                <div className="col-12 col-md-9 col-lg-6 ">
                    <div className="card   bg-gradient border-0 ">
                        <div className="cardbody p-5 d-flex flex-column align-items-center  ">
                            <span
                                onClick={() => {
                                    setshowKey(false);
                                }}
                                className="px-3 position-absolute top-0 right-0 "
                            >
                                <a href="#">
                                    <i className="far fa-close text-white fa-2x"></i>
                                </a>
                            </span>

                            <div className="   rounded-custom  w-100  ">
                                <h3 className="text-center">{item.key_name}</h3>

                                <div className="d-flex justify-content-end align-items-center gap-2">
                                    <button
                                        class="btn bg-pink py-1 px-4 mb-3"
                                        style={{
                                            fontSize: 12,
                                        }}
                                        onClick={() => setIsEdit(!isEdit)}
                                    >
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </button>
                                    <button
                                        class="btn bg-pink py-1 px-4 mb-3"
                                        style={{
                                            fontSize: 12,
                                        }}
                                        onClick={() => {
                                            setshowKey(false);
                                            __handleDeleteKey(item._id);
                                        }}
                                    >
                                        <i class="fa-sharp fa-solid fa-trash"></i>
                                    </button>
                                    <button
                                        class="btn bg-pink py-1 px-4 mb-3"
                                        style={{
                                            fontSize: 12,
                                        }}
                                        onClick={__handleCopyClick}
                                    >
                                        COPY
                                    </button>
                                </div>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        __handleUpdate();
                                    }}
                                    className="mt-0"
                                >
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <div className="input-group ">
                                                <textarea
                                                    readOnly={!isEdit}
                                                    id={`yourkey${item._id}`}
                                                    className={`form-control ${
                                                        error.key &&
                                                        "border-danger"
                                                    } `}
                                                    placeholder=""
                                                    style={{
                                                        height: "120px",
                                                    }}
                                                    value={key}
                                                    onChange={(e) => {
                                                        setkey(e.target.value);
                                                    }}
                                                ></textarea>
                                            </div>
                                            {error.key && (
                                                <p
                                                    style={{
                                                        fontSize: 10,
                                                        color: "red",
                                                        padding: 0,
                                                        margin: 0,
                                                    }}
                                                >
                                                    {error.key}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    {isEdit && (
                                        <button
                                            class="btn bg-pink py-1 px-4 mb-0 float-end border-2"
                                            style={{
                                                fontSize: 12,
                                            }}
                                            type="submit"
                                        >
                                            SAVE
                                        </button>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

const SecretList = () => {
    let navigate = useNavigate();
    const [showAddSecretKey, setshowAddSecretKey] = useState(false);
    const [search, setSearch] = useState("");
    const [list, setList] = useState([]);
    const [listloading, setListloading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(null);
    const [showError, setshowError] = useState(false);
    const [error, setError] = useState({});
    const [secretKey, setSecret] = useState({
        key_name: "",
        key: "",
    });

    const __handleSubmit = () => {
        let alertdata = { key_name: "", key: "" };

        if (!secretKey.key_name.trim()) {
            alertdata.key_name = "Fill key name";
        } else if (Number(secretKey.key_name.trim().length) < 5) {
            alertdata.key_name = "Key name must be atleast 5 characters";
        }
        if (!secretKey.key.trim()) {
            alertdata.key = "Fill your secret key";
        }

        setError((prv) => ({
            ...prv,
            ...alertdata,
        }));

        if (__hasAnyValue(alertdata)) {
            return;
        }
        setLoading(true);
        setProgress(10);
        setTimeout(() => {
            setProgress(50);
        }, 10);
        __makeCreateSecretPostRequest(secretKey)
            .then((res) => {
                setProgress(80);

                console.log(res);
                if (res.response.response_code === "200") {
                    setshowAddSecretKey(false);
                    setList([res.data, ...list]);
                }
                setProgress(100);
                setTimeout(() => {
                    setLoading(false);
                    setSecret({
                        key_name: "",
                        key: "",
                    });
                }, 100);
            })
            .catch((error) => {
                setProgress(100);
                console.error(error);
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            });
    };
    const __handleDeleteKey = (key_id) => {
        console.log(key_id);
        setLoading(true);
        setProgress(10);
        setTimeout(() => {
            setProgress(50);
        }, 10);
        __makeDeleteSecretKeyDeleteRequest(key_id)
            .then((res) => {
                console.log(res);
                setProgress(80);

                if (res.response.response_code === "200") {
                    setList(list.filter((key) => key._id !== key_id));
                }
                setProgress(100);
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            });
    };

    const __handleGetData = () => {
        if (!__userToken()) return navigate("/login");
        if (!__authToken()) return;

        setListloading(true);

        __makeGetKeyGetRequest()
            .then((res) => {
                console.log(res);

                if (res.response.response_code == "200") {
                    setList(res.data.reverse());
                } else {
                    setshowError(true);
                }
                setListloading(false);
            })
            .catch((error) => {
                setListloading(false);
                setshowError(true);
                console.error(error);
            });
    };
    useEffect(() => {
        __handleGetData();
    }, []);

    return (
        <>
            <ProgressBar isShow={loading} progress={progress} />

            {showError && <SomeError />}
            {showAddSecretKey && addSecretKey()}
            <section className="services-icon ptb-120 pt-60">
                <div className="container">
                    <div className="row justify-content-end ">
                        <div className="col-lg-4 col-md-6 col-sm-9 ">
                            <form
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                className="newsletter-form position-relative d-flex d-lg-flex d-md-flex align-items-center mb-5"
                            >
                                <input
                                    type="text"
                                    className="input-newsletter form-control me-2"
                                    placeholder="Search your keys..."
                                    name="search"
                                    autoComplete="off"
                                    style={{ maxHeight: "50px" }}
                                    onChange={(e) => setSearch(e.target.value)}
                                />

                                <button
                                    className="btn bg-pink mx-2 border-2"
                                    onClick={() => setshowAddSecretKey(true)}
                                >
                                    <i class="fa-sharp fa-solid fa-plus fa-2xm"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {!listloading ? (
                            list.filter(
                                (item) =>
                                    item.key_name
                                        .toLocaleLowerCase()
                                        .search(search?.toLocaleLowerCase()) >=
                                    0
                            ).length > 0 ? (
                                list
                                    .filter(
                                        (item) =>
                                            item.key_name
                                                .toLocaleLowerCase()
                                                .search(
                                                    search?.toLocaleLowerCase()
                                                ) >= 0
                                    )
                                    .map((item, i) => (
                                        <SecretKeyCom
                                            item={item}
                                            __handleDeleteKey={
                                                __handleDeleteKey
                                            }
                                        />
                                    ))
                            ) : (
                                <div className="col-12 ">
                                    <div className="error-page-content-wrap d-flex flex-column justify-content-center align-items-center">
                                        {list.length > 0 && (
                                            <h2 className="error-404 text-warning">
                                                404
                                            </h2>
                                        )}
                                        {list.length == 0 && (
                                            <div className="d-flex justify-content-center">
                                                <div className="col-10 col-lg-6 col-md-8 ">
                                                    <img
                                                        src="assets/img/create.jpg"
                                                        alt=""
                                                        width={"100%"}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <h1 className="display-5 fw-bold text-center">
                                            {list.length == 0
                                                ? "Create Your First Repositorie"
                                                : "Repositorie Not Found"}
                                        </h1>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="d-flex justify-content-center">
                                <PacmanLoader color="#fd035a" size={20} />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );

    function addSecretKey() {
        return (
            <div
                className="position-fixed d-flex justify-content-center align-items-center z-10"
                style={{ inset: 1, background: "rgba(255,255,255,0.2)" }}
            >
                <div className="card   bg-gradient border-0">
                    <div className="cardbody p-5 d-flex flex-column align-items-center ">
                        <span
                            onClick={() => {
                                setshowAddSecretKey(false);
                                setError({});
                            }}
                            className="px-3 position-absolute top-0 right-0 "
                        >
                            <a href="#">
                                <i className="far fa-close text-white fa-2x"></i>
                            </a>
                        </span>

                        <div
                            className="   rounded-custom   "
                            style={{ maxWidth: "500px" }}
                        >
                            <h5>
                                <span
                                    className="text-pink"
                                    style={{ fontSize: "30px" }}
                                >
                                    Create New{" "}
                                </span>{" "}
                                secret key
                            </h5>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    __handleSubmit();
                                }}
                                className="mt-4"
                            >
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="input-group ">
                                            <input
                                                type="text"
                                                className={`form-control ${
                                                    error.key_name &&
                                                    "border-danger"
                                                } `}
                                                placeholder="Your key name"
                                                aria-label="Your name"
                                                value={secretKey.key_name}
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value.length >
                                                        30
                                                    ) {
                                                        return setError(
                                                            (prv) => ({
                                                                ...prv,
                                                                key_name:
                                                                    "Key name must be  less then 30 characters",
                                                            })
                                                        );
                                                    }
                                                    setSecret((prev) => ({
                                                        ...prev,
                                                        key_name:
                                                            e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        {error.key_name && (
                                            <p
                                                style={{
                                                    fontSize: 10,
                                                    color: "red",
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                {error.key_name}
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="input-group ">
                                            <textarea
                                                className={`form-control ${
                                                    error.key && "border-danger"
                                                } `}
                                                placeholder="Describe your sercet key"
                                                style={{ height: "120px" }}
                                                value={secretKey.key}
                                                onChange={(e) => {
                                                    setSecret((prev) => ({
                                                        ...prev,
                                                        key: e.target.value,
                                                    }));
                                                }}
                                            ></textarea>
                                        </div>
                                        {error.key && (
                                            <p
                                                style={{
                                                    fontSize: 10,
                                                    color: "red",
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                {error.key}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <button
                                    className="btn btn-pink bg-pink border-2"
                                    type="submit"
                                >
                                    Create
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default SecretList;
