import React from "react";
import { PacmanLoader } from "react-spinners";

const Loader = () => {
    return (
        <React.Fragment>
            <div className="web-loader">
                <div className="d-flex flex-column align-items-center">
                    <video
                        src="assets/img/logo/NoteBook_1.mp4"
                        style={{ maxWidth: "500px" }}
                        autoPlay
                        muted
                        // loop
                    ></video>
                    {/* <PacmanLoader color="#fd035a" size={25} /> */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Loader;
