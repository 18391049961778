import React from "react";
// error_img
const SomeError = () => {
    return (
        <div
            className="position-fixed d-flex justify-content-center align-items-center   flex-column"
            style={{ inset: 0, background: "rgba(0,0,0,0.8)", zIndex: 100 }}
        >
            <div className="col-8 col-lg-4 col-md-8 ">
                <img src="assets/img/error_img.png" alt="" width={"100%"} />
            </div>
            <h1
                className="display-7 fw-bold text-center mx-3"
                style={{ color: "#FF7833", maxWidth: "800px" }}
            >
                Some Technical Issue Please Refresh The Page.
            </h1>
        </div>
    );
};

export default SomeError;
