import React, { useEffect, useState } from "react";
import PageHeader from "../components/common/PageHeader";
import PageMeta from "../components/common/PageMeta";
import FooterOne from "../layout/Footer/FooterOne";
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";
import EventCounter from "../components/counter/EventCounter";
import EventSession from "../components/events/EventSession";
import {
    __makeCreateNotePostRequest,
    __makeDeleteNoteDeleteRequest,
    __makeDeleteRepoDeleteRequest,
    __makeGetRepoDetailsGetRequest,
    __makeUpdateNotePutRequest,
} from "../functions/apifun";
import { useNavigate } from "react-router-dom";
import {
    __authToken,
    __hasAnyValue,
    __userToken,
} from "../functions/constantfun";
import SomeError from "../components/common/SomeError";
import ProgressBar from "../components/common/ProgressBar";

const SingleRepo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let navigate = useNavigate();
    const [repo, setrepo] = useState();
    const [repoNotes, setrepoNotes] = useState();
    const [showAddNote, setshowAddNote] = useState(false);
    const [showDelete, setshowDelete] = useState(false);
    const [error, setError] = useState({});
    const [showError, setshowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(null);
    const [note, setNote] = useState({
        note_query: "",
        note_summary: "",
        repo_id: urlParams.get("i"),
    });

    const __handleGetRepoDetails = () => {
        if (!__userToken()) return navigate("/login");
        if (!__authToken()) return;

        __makeGetRepoDetailsGetRequest(urlParams.get("i"))
            .then((res) => {
                console.log(res);
                if (res.response.response_code === "200") {
                    setrepo(res.data.repo_details);
                    setrepoNotes(res.data.notes.reverse());
                } else if (res.response.response_code === "404") {
                    navigate("/repositories");
                } else {
                    setshowError(true);
                    setrepoNotes([]);
                    setrepo({});
                }
            })
            .catch((error) => {
                console.error(error);
                setshowError(true);
                setrepoNotes([]);
                setrepo({});
            });
    };

    const __handleSubmit = () => {
        let alertdata = { note_query: "", note_summary: "" };

        if (!note.note_query) {
            alertdata.note_query = "Enter query";
        } else if (Number(note.note_query.trim().length) < 5) {
            alertdata.note_query = "Query must be atleast 5 characters";
        }

        if (!note.note_summary) {
            alertdata.note_summary = "Enter query summary";
        } else if (Number(note.note_summary.trim().length) < 5) {
            alertdata.note_summary =
                "Query summary must be atleast 5 characters";
        }

        setError((prv) => ({
            ...prv,
            ...alertdata,
        }));

        if (__hasAnyValue(alertdata)) {
            return;
        }
        setLoading(true);
        setProgress(10);
        setTimeout(() => {
            setProgress(50);
        }, 10);
        __makeCreateNotePostRequest(note)
            .then((res) => {
                setProgress(80);
                console.log(res);
                if (res.response.response_code === "200") {
                    setshowAddNote(false);
                    setrepoNotes([res.data, ...repoNotes]);
                } else if (res.response.response_code === "406") {
                    let newError = {};
                    res.data.error.forEach((element) => {
                        newError[element.param] = element.msg;
                    });
                    console.log(newError);
                    setError(newError);
                }
                setProgress(100);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    };

    const __handleDeleteNotes = (note_id) => {
        setLoading(true);
        setProgress(10);
        setTimeout(() => {
            setProgress(50);
        }, 10);
        __makeDeleteNoteDeleteRequest(note_id)
            .then((res) => {
                console.log(res);
                setProgress(80);

                if (res.response.response_code === "200") {
                    setrepoNotes(
                        repoNotes.filter((note) => note._id !== note_id)
                    );
                }
                setProgress(100);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    };

    const __handleDeleteRepo = () => {
        setLoading(true);
        setProgress(10);
        setTimeout(() => {
            setProgress(50);
        }, 10);
        __makeDeleteRepoDeleteRequest(repo._id)
            .then((res) => {
                console.log(res);
                setProgress(80);
                if (res.response.response_code === "200") {
                    setTimeout(() => {
                        navigate("/repositories");
                    }, 500);
                }
                setProgress(100);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    };

    useEffect(() => {
        __handleGetRepoDetails();
    }, []);

    return (
        <Layout>
            <ProgressBar isShow={loading} progress={progress} />
            {showError && <SomeError />}
            <PageMeta title="Contact-Software &amp; IT Solutions HTML Template" />
            <Navbar navDark />
            <EventCounter repoDetails={repo} />
            <EventSession
                repoNotes={repoNotes}
                repoName={repo?.repo_name}
                repoCreateAt={repo?.createdAt}
                __handleDeleteNotes={__handleDeleteNotes}
            />
            <div
                className="position-fixed  d-flex flex-column align-items-center justify-content-center"
                style={{ bottom: "50px", right: "50px", zIndex: 10 }}
            >
                {deleteButton()}
                {addButton()}
            </div>
            {showAddNote && addNote()}
            {showDelete && deleteRepo()}
        </Layout>
    );

    function deleteButton() {
        return (
            <i
                class=" fa-sharp fa-solid fa-trash  delete-btn mb-2"
                onClick={() => setshowDelete(true)}
            ></i>
        );
    }

    function addButton() {
        return (
            <button
                className="btn   bg-pink  rounded-circle border-2 d-flex align-items-center justify-content-center"
                onClick={() => setshowAddNote(true)}
                style={{ width: "60px", height: "60px" }}
            >
                <i class="fa-sharp fa-solid fa-plus "></i>
            </button>
        );
    }
    function deleteRepo() {
        return (
            <div
                className="position-fixed d-flex justify-content-center align-items-center z-10"
                style={{ inset: 1, background: "rgba(255,255,255,0.2)" }}
            >
                <div className="card   bg-gradient border-0">
                    <div className="cardbody p-5 d-flex flex-column align-items-center ">
                        <span
                            onClick={() => {
                                setshowDelete(false);
                                setError({});
                            }}
                            className="px-3 position-absolute top-0 right-0 "
                        >
                            <a href="#">
                                <i className="far fa-close text-white fa-2x"></i>
                            </a>
                        </span>

                        <div
                            className="rounded-custom text-center "
                            style={{ maxWidth: "800px" }}
                        >
                            <h5>
                                Are You Sure Your Want To Delete This
                                Repositorie?
                            </h5>
                            <button
                                className="btn bg-pink border-2 mx-auto  mt-4"
                                onClick={__handleDeleteRepo}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    function addNote() {
        return (
            <div
                className="position-fixed d-flex justify-content-center align-items-center z-10"
                style={{ inset: 1, background: "rgba(255,255,255,0.2)" }}
            >
                <div className="card   bg-gradient border-0">
                    <div className="cardbody p-5 d-flex flex-column align-items-center ">
                        <span
                            onClick={() => {
                                setshowAddNote(false);
                                setError({});
                            }}
                            className="px-3 position-absolute top-0 right-0 "
                        >
                            <a href="#">
                                <i className="far fa-close text-white fa-2x"></i>
                            </a>
                        </span>

                        <div
                            className="   rounded-custom "
                            style={{ maxWidth: "800px" }}
                        >
                            <h5>
                                <span
                                    className="text-pink"
                                    style={{ fontSize: "30px" }}
                                >
                                    Create New{" "}
                                </span>{" "}
                                Note
                            </h5>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    __handleSubmit();
                                }}
                                className="mt-4"
                            >
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="input-group ">
                                            <input
                                                style={{ minWidth: "300px" }}
                                                type="text"
                                                className={`form-control ${
                                                    error.note_query &&
                                                    "border-danger"
                                                } `}
                                                placeholder="Enter query..."
                                                aria-label="Your name"
                                                value={note.note_query}
                                                onChange={(e) => {
                                                    setNote((prev) => ({
                                                        ...prev,
                                                        note_query:
                                                            e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        {error.note_query && (
                                            <p
                                                style={{
                                                    fontSize: 10,
                                                    color: "red",
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                {error.note_query}
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="input-group ">
                                            <textarea
                                                className={`form-control ${
                                                    error.note_summary &&
                                                    "border-danger"
                                                } `}
                                                placeholder="Enter summary..."
                                                style={{
                                                    height: "200px",
                                                    minWidth: "300px",
                                                }}
                                                value={note.note_summary}
                                                onChange={(e) => {
                                                    setNote((prev) => ({
                                                        ...prev,
                                                        note_summary:
                                                            e.target.value,
                                                    }));
                                                }}
                                            ></textarea>
                                        </div>
                                        {error.note_summary && (
                                            <p
                                                style={{
                                                    fontSize: 10,
                                                    color: "red",
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                {error.note_summary}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <button
                                    className="btn bg-pink border-2 "
                                    type="submit"
                                >
                                    Create
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
export default SingleRepo;
