import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    __authToken,
    __getRandomImage,
    __hasAnyValue,
    __userToken,
} from "../../functions/constantfun";
import {
    __makeCreateRepoPostRequest,
    __makeGetRepoGetRequest,
} from "../../functions/apifun";
import { PacmanLoader } from "react-spinners";
import SomeError from "../common/SomeError";
import ProgressBar from "../common/ProgressBar";

const RepositorieCom = React.memo(({ item }) => {
    return (
        <Link
            to={"/repository-details?i=" + item._id + "&n=" + item.repo_name}
            className="col-lg-4 col-md-6 mt-4 "
            style={{ cursor: "pointer" }}
        >
            <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                <span className="fad fa-comment-alt-lines fa-3x text-primary"></span>
                <div className="contact-promo-info mb-4">
                    <h5>{item.repo_name}</h5>
                    <p className="text-dark">{item.repo_description}</p>
                </div>
                <Link
                    to={
                        "/repository-details?i=" +
                        item._id +
                        "&n=" +
                        item.repo_name
                    }
                    className="btn btn-link mt-auto"
                >
                    View Details
                </Link>
            </div>
        </Link>
    );
});

const RepositoriesList = () => {
    let navigate = useNavigate();
    const [setshowAddRepo, setsetshowAddRepo] = useState(false);
    const [search, setSearch] = useState("");
    const [list, setList] = useState([]);
    const [listloading, setListloading] = useState(true);
    const [showError, setshowError] = useState(false);
    const [error, setError] = useState({});
    const [repo, setRepo] = useState({
        repo_name: "",
        repo_description: "",
    });
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(null);

    const __handleSubmit = () => {
        let alertdata = { repo_name: "", repo_description: "" };

        if (!repo.repo_name.trim()) {
            alertdata.repo_name = "Fill repository name";
        } else if (Number(repo.repo_name.trim().length) < 5) {
            alertdata.repo_name =
                "Repository name must be atleast 5 characters";
        }

        setError((prv) => ({
            ...prv,
            ...alertdata,
        }));

        if (__hasAnyValue(alertdata)) {
            return;
        }
        setLoading(true);
        setProgress(10);
        setTimeout(() => {
            setProgress(50);
        }, 10);
        __makeCreateRepoPostRequest(repo)
            .then((res) => {
                console.log(res);
                setProgress(80);

                if (res.response.response_code === "200") {
                    setsetshowAddRepo(false);
                    setList([res.data, ...list]);
                }
                setProgress(100);
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            });
    };

    const __handleGetRepositories = () => {
        if (!__userToken()) return navigate("/login");
        if (!__authToken()) return;

        setListloading(true);

        __makeGetRepoGetRequest()
            .then((res) => {
                console.log(res);

                if (res.response.response_code == "200") {
                    setList(res.data.repositories.reverse());
                } else {
                    setshowError(true);
                }
                setListloading(false);
            })
            .catch((error) => {
                setListloading(false);
                setshowError(true);
                console.error(error);
            });
    };
    useEffect(() => {
        __handleGetRepositories();
    }, []);

    return (
        <>
            <ProgressBar isShow={loading} progress={progress} />
            {showError && <SomeError />}
            {setshowAddRepo && addrepo()}
            <section className="services-icon ptb-120 pt-60">
                <div className="container">
                    <div className="row justify-content-end ">
                        <div className="col-lg-4 col-md-6 col-sm-9 ">
                            <form
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                className="newsletter-form position-relative d-flex d-lg-flex d-md-flex align-items-center mb-5"
                            >
                                <input
                                    type="text"
                                    className="input-newsletter form-control me-2"
                                    placeholder="Search your repositorie..."
                                    name="search"
                                    autoComplete="off"
                                    style={{ maxHeight: "50px" }}
                                    onChange={(e) => setSearch(e.target.value)}
                                />

                                <button
                                    className="btn bg-pink mx-2 border-2"
                                    onClick={() => setsetshowAddRepo(true)}
                                >
                                    <i class="fa-sharp fa-solid fa-plus fa-2xm"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {!listloading ? (
                            list.filter(
                                (item) =>
                                    item.repo_name
                                        .toLocaleLowerCase()
                                        .search(search.toLocaleLowerCase()) >=
                                        0 ||
                                    item.repo_description
                                        .toLocaleLowerCase()
                                        .search(search.toLocaleLowerCase()) >= 0
                            ).length > 0 ? (
                                list
                                    .filter(
                                        (item) =>
                                            item.repo_name
                                                .toLocaleLowerCase()
                                                .search(
                                                    search.toLocaleLowerCase()
                                                ) >= 0 ||
                                            item.repo_description
                                                .toLocaleLowerCase()
                                                .search(
                                                    search.toLocaleLowerCase()
                                                ) >= 0
                                    )
                                    .map((item, i) => (
                                        <RepositorieCom item={item} />
                                    ))
                            ) : (
                                <div className="col-12 ">
                                    <div className="error-page-content-wrap d-flex flex-column justify-content-center align-items-center">
                                        {list.length > 0 && (
                                            <h2 className="error-404 text-warning">
                                                404
                                            </h2>
                                        )}
                                        {list.length == 0 && (
                                            <div className="d-flex justify-content-center">
                                                <div className="col-10 col-lg-6 col-md-8 ">
                                                    <img
                                                        src="assets/img/create.jpg"
                                                        alt=""
                                                        width={"100%"}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <h1 className="display-5 fw-bold text-center">
                                            {list.length == 0
                                                ? "Create Your First Repositorie"
                                                : "Repositorie Not Found"}
                                        </h1>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="d-flex justify-content-center">
                                <PacmanLoader color="#fd035a" size={20} />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );

    function addrepo() {
        return (
            <div
                className="position-fixed d-flex justify-content-center align-items-center z-10"
                style={{ inset: 1, background: "rgba(255,255,255,0.2)" }}
            >
                <div className="card   bg-gradient border-0">
                    <div className="cardbody p-5 d-flex flex-column align-items-center ">
                        <span
                            onClick={() => {
                                setsetshowAddRepo(false);
                                setError({});
                            }}
                            className="px-3 position-absolute top-0 right-0 "
                        >
                            <a href="#">
                                <i className="far fa-close text-white fa-2x"></i>
                            </a>
                        </span>

                        <div
                            className="   rounded-custom   "
                            style={{ maxWidth: "500px" }}
                        >
                            <h5>
                                <span
                                    className="text-pink"
                                    style={{ fontSize: "30px" }}
                                >
                                    Create New{" "}
                                </span>{" "}
                                repository
                            </h5>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    __handleSubmit();
                                }}
                                className="mt-4"
                            >
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="input-group ">
                                            <input
                                                type="text"
                                                className={`form-control ${
                                                    error.repo_name &&
                                                    "border-danger"
                                                } `}
                                                placeholder="Your repository name"
                                                aria-label="Your name"
                                                value={repo.repo_name}
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value.length >
                                                        30
                                                    ) {
                                                        return setError(
                                                            (prv) => ({
                                                                ...prv,
                                                                repo_name:
                                                                    "Repository name must be  less then 30 characters",
                                                            })
                                                        );
                                                    }
                                                    setRepo((prev) => ({
                                                        ...prev,
                                                        repo_name:
                                                            e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        {error.repo_name && (
                                            <p
                                                style={{
                                                    fontSize: 10,
                                                    color: "red",
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                {error.repo_name}
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-12 mb-3">
                                        <div className="input-group ">
                                            <textarea
                                                className={`form-control ${
                                                    error.repo_description &&
                                                    "border-danger"
                                                } `}
                                                placeholder="Describe your repository (Optional)"
                                                style={{ height: "120px" }}
                                                maxLength={100}
                                                value={repo.repo_description}
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value.length >=
                                                        100
                                                    ) {
                                                        return setError(
                                                            (prv) => ({
                                                                ...prv,
                                                                repo_description:
                                                                    "Repository about must be  less then 100 characters",
                                                            })
                                                        );
                                                    }
                                                    setRepo((prev) => ({
                                                        ...prev,
                                                        repo_description:
                                                            e.target.value,
                                                    }));
                                                }}
                                            ></textarea>
                                        </div>
                                        {error.repo_description && (
                                            <p
                                                style={{
                                                    fontSize: 10,
                                                    color: "red",
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                {error.repo_description}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <button
                                    className="btn btn-pink bg-pink border-2"
                                    type="submit"
                                >
                                    Create
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default RepositoriesList;
