import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeSassOne from "../themes/index1/HomeSassOne";
import Login from "../pages/onboard/Login";
import NotFoundScreen from "../components/others/NotFoundScreen";
import PasswordReset from "../pages/onboard/PasswordReset";
import ScrollToTop from "../components/common/ScrollToTop";

import About from "../pages/About";
import Contact from "../pages/Contact";
import ComingSoon from "../pages/ComingSoon";

import Signup from "../pages/onboard/Signup";
import Repositories from "../pages/Repositories";
import SingleRepo from "../pages/SingleRepo";
import SecretKey from "../pages/SecretKey";
import Setting from "../pages/Setting";
import HomeTen from "../themes/index10/HomeTen";

function index() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {/* <Route exact path="/" element={<HomeSassOne />} /> */}
                <Route exact path="/" element={<HomeTen />} />
                <Route exact path="/about-us" element={<About />} />
                <Route exact path="/contact-us" element={<Contact />} />
                <Route exact path="/repositories" element={<Repositories />} />
                <Route exact path="/secret-keys" element={<SecretKey />} />
                <Route exact path="/app-setting" element={<Setting />} />
                <Route
                    exact
                    path="/repository-details"
                    element={<SingleRepo />}
                />

                <Route
                    exact
                    path="/password-reset"
                    element={<PasswordReset />}
                />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/signup" element={<Signup />} />
                <Route exact path="/coming-soon" element={<ComingSoon />} />

                <Route path="*" element={<NotFoundScreen />} />
            </Routes>
        </BrowserRouter>
    );
}

export default index;
